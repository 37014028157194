import {useNavigate, useParams} from "react-router-dom";
import {
    Card,
    CardContent, CircularProgress,
    Snackbar,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Close, ContentCopy} from "@mui/icons-material";
import Box from "@mui/material/Box";
import axiosInstance from "../utils/axiosInstance";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";


function PublicToken() {
    const {hash} = useParams();
    const [initialized, setInitialized] = useState(false);
    const [token, setToken] = useState('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarText, setSnackbarText] = React.useState("");

    useEffect(() => {
        const intervalId = setInterval(async () => {
            try {
                const {data} = await axiosInstance.get(`/hash/${hash}`, {withCredentials: true});
                setToken(data)
                setInitialized(true)
            } catch (error) {
                if (error.response['status'] === 400) {
                    clearInterval(intervalId);
                    setToken({token: "Account does not exist."})
                    setInitialized(true)
                }
            }
        }, 500);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleClick = () => {
        handleOpenSnackbar("Access Token Copied.")
        navigator.clipboard.writeText(token.token)
    };

    const handleOpenSnackbar = (text) => {
        setOpenSnackbar(true)
        setSnackbarText(text)
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        setSnackbarText("")
    };

    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                onClick={handleCloseSnackbar}
            >
                <Close fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    if (!initialized) {
        return (
            <>
                <Box align="center">
                    <CircularProgress/>
                </Box>
                <Typography variant="h4" align="center">
                    Loading..
                </Typography>
            </>
        );
    }

    return (
        <Box justifyContent="center" alignItems="center" textAlign="center">
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message={snackbarText}
                action={snackbarAction}
                ContentProps={{
                    sx: {
                        background: "#20232a",
                        color: "#fff"
                    }
                }}
            />
            <img src={'/images/full-logo.svg'} className="App-logo" alt="logo"/>
            <Card
                sx={{
                    backdropFilter: 'blur(1px)',
                    backgroundColor: 'rgba(0,0,0,0.43)',
                    padding: {xs: 1, md: 1},
                    '& > *': {
                        flexGrow: 1,
                        flexBasis: '50%'
                    },
                    mb: 5
                }}
            >
                <CardContent align="center" sx={{width: '40rem'}}>
                    <Typography variant="h3">Access Token</Typography>
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <Typography variant="h4">{token.token}
                        <IconButton color="primary" aria-label="Copy token" sx={{mt: '-4px'}} onClick={handleClick}>
                            <ContentCopy/>
                        </IconButton>
                    </Typography>

                </CardContent>
            </Card>
        </Box>
    );
}

export default PublicToken;