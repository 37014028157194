import {Button, Card, CardContent, CssBaseline, Input, Typography} from "@mui/material";
import React, {useCallback, useRef} from "react";
import {loadFull} from "tsparticles";
import Particles from "react-particles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {Outlet, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid2";

const Layout = () => {
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    return (
        <Box>
            <CssBaseline/>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <Grid item>
                    <Outlet />
                </Grid>
            </Grid>
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    fullScreen: {
                        enable: true,
                        zIndex: -1
                    },
                    fpsLimit: 120,
                    particles: {
                        number: {
                            value: 250,
                            density: {
                                enable: true
                            }
                        },
                        shape: {
                            type: 'none'
                        },
                        line_linked: {
                            enable: true,
                            distance: 100,
                            color: 'rgba(255,143,0,0.6)',
                            opacity: 0.3,
                            width: 1
                        },
                        move: {
                            enable: true,
                            speed: 1,
                            direction: 'none',
                            random: false,
                            straight: false,
                            out_mode: 'out',
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200
                            }
                        }
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: 'grab',
                                parallax: {
                                    enable: true,
                                    smooth: 10,
                                    force: 60
                                }
                            },
                            resize: true
                        },
                        modes: {
                            grab: {
                                distance: 200,
                                line_linked: {
                                    opacity: 0.2
                                }
                            },
                            bubble: {
                                distance: 400,
                                size: 40,
                                duration: 2,
                                opacity: 0.8,
                                speed: 3
                            },
                            repulse: {
                                distance: 200
                            },
                            push: {
                                particles_nb: 4
                            },
                            remove: {
                                particles_nb: 2
                            }
                        }
                    },
                    retina_detect: true
                }}
            />
        </Box>
    );
};
export default Layout;