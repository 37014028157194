import {Button, Card, CardContent, Input, Typography} from "@mui/material";
import React, {useRef} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import Layout from "./Layout";

const IndexPage = () => {
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const handleNavigate = () => {
        const value = inputRef.current?.value.trim();
        if (value) {
            navigate(`/${value}`);
        }
    };

    return (
            <Box justifyContent="center" alignItems="center" textAlign="center">
                <img src={'/images/full-logo.svg'} className="App-logo" alt="logo"/>
                <Card
                    sx={{
                        backdropFilter: 'blur(1px)',
                        backgroundColor: 'rgba(0,0,0,0.43)',
                        padding: {xs: 1, md: 1},
                        '& > *': {
                            flexGrow: 1,
                            flexBasis: '50%'
                        },
                        mb: 5
                    }}
                >
                    <CardContent align="center" sx={{width: '40rem'}}>
                        <Typography variant="h3">Hash</Typography>
                        <Divider sx={{mt: 2, mb: 2}}/>
                        <Box sx={{display: "flex", gap: 2, alignItems: "center"}}>
                            <Input
                                inputRef={inputRef}
                                fullWidth
                                sx={{
                                    backgroundColor: "rgba(255,255,255,0.1)",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                                onKeyDown={(e) => e.key === "Enter" && handleNavigate()}
                            />
                            <Button variant="contained" onClick={handleNavigate} sx={{
                                padding: "10px",
                                borderRadius: "5px",
                            }}
                            >
                                Go
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
    );
};
export default IndexPage;