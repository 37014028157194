import ThemeCustomization from "./themes";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import './assets/fonts/Azonix.otf';
import './assets/css/App.css';
import HashSite from "./pages/HashPage";
import IndexPage from "./pages/IndexPage";
import Layout from "./pages/Layout";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ThemeCustomization>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<IndexPage />}/>
                    <Route path="/:hash" element={<HashSite/>}/>
                </Route>
            </Routes>
        </ThemeCustomization>
    </BrowserRouter>
);