import React from 'react';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#fb923c',
            contrastText: '#20232a'
        },
        secondary: {
            main: '#B78CD8',
            contrastText: '#20232a'
        },
        success: {
            main: '#6FF5BA',
            contrastText: '#20232a'
        },
        info: {
            main: '#6faaf5',
            contrastText: '#20232a'
        },
        error: {
            main: '#f5776f',
            contrastText: '#20232a'
        },
        background: {
            default: '#20232a'
        }
    }
});

const ThemeCustomization = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemeCustomization;
